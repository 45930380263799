import HeroImage from '../images/banner.jpg';
import HeroImageMobile from '../images/banner-mobile.jpg';


const HeroBanner = () => (

    <div className="hero w-full bg-tone-darkpurple text-white  text-center flex items-center justify-center overflow-hidden relative pt-4 md:pt-0">
        <div className="hero-image w-full inset-0 overflow-hidden">
			<img className='object-cover hidden lg:block lg:h-[450px] 2xl:h-full pt-8 w-full' src={HeroImage} />
			<img className='object-cover block lg:hidden w-full h-[240px] md:h-auto pt-6' src={HeroImageMobile} />
        </div>
		<div className="hero__caption max-w-screen-lg relative z-40">
			<h1 className="hidden text-white md:text-6xl 2xl:text-9xl font-upheaval uppercase tracking-wide">Tone Arcade</h1>
			<h2 className="hidden text-tone-purple text-4xl mt-4">
				Check out our games selection
			</h2>
		</div>
	</div>
)

export default HeroBanner;
