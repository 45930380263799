const Footer = () => {
    return (
          <footer role="contentinfo" className="border-t border-white border-opacity-10 py-6 md:py-12 bg-tone-darkpurple">
            <div className="container mx-auto text-white">
              
              <p className='text-sm md:text-base font-body'>&copy;2022 Tone Agency.</p>
            
            </div>
          </footer>
    )
}
export default Footer;
