import './App.css';
import HeroBanner from './components/HeroBanner'
import Header from './components/Header'
import Games from './components/Games'
import Footer from './components/Footer'
import Book from './components/Book'


function App() {


  return (
    <div className="App antialiased font-normal text-wh-font">
        <Header />
        <HeroBanner />
        <Games />
        <Book />
        <Footer />
    </div>
  );
}

export default App;
