import ToneLogo from '../images/tone-logo.svg';

import React, { useState, useEffect, useRef } from 'react';



const Header = () => {
    const [ isShrunk, setShrunk ] = useState(false);
    const headerRef = useRef(null);

    useEffect(() => {
        let options = {
            root: headerRef.current,
            rootMargin: '0px',
            threshold: 1.0
        };

          let observer = new IntersectionObserver((entries, observer) => {
              entries.forEach(entry => {
                if( entry.boundingClientRect.y < 0 && !isShrunk ){
                    setShrunk(true);
                }
                else if( entry.boundingClientRect.y > 0 && isShrunk ){
                    setShrunk(false);
                }
              });
          }, options);
    }, []);

    return (
        <div ref={headerRef} className="header-holder">
            <header className={`absolute top-0 w-full p-4 md:p-8 z-40 ${isShrunk ? 'shrunk' : ''}`}>
                <div className="flex justify-between items-center">
                    <div className="logo w-16 md:w-48">
                        <img src={ToneLogo} />
                    </div>
                    <a className="rounded-lg xl:rounded-xl leading-none transition hover:bg-tone-pink hover:text-white py-2 px-4 md:py-4 md:px-8  uppercase font-superstar text-base md:text-2xl tracking-wide border-2 border-tone-pink text-tone-pink" href='https://calendly.com/toneagency/tone-agency-call'>Book a call</a>
                </div>
            </header>
        </div>
        )
}

export default Header;
