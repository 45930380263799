const Book = () => {
    return (
      <div className="bg-tone-darkpurple text-white py-8 md:py-12">
      <div className="md:container mx-auto w-full md:w-[90%] lg:max-w-[1500px] md:pl-0">
        <div className="section-title px-6 lg:px-0 text-center mx-auto max-w-screen-md pb-8">
          <h2 className="font-bold text-3xl md:text-4xl lg:text-5xl xl:text-6xl uppercase tracking tracking-wider mb-4 md:mb-6 text-tone-pink grad">Book a call</h2>
          <p className="font-body text-sm md:text-xl leading-normal xl:leading-relaxed">So we'd love to grab you for a quick conversation to talk you through all of this so hit the button and let's get a call booked in!</p>
          <a className="mt-6 md:mt-8 rounded-lg inline-block xl:rounded-xl leading-none transition bg-tone-pink text-tone-darkpurple hover:bg-tone-purple hover:text-white py-4 px-8  uppercase font-superstar md:text-2xl tracking-wide  text-lg" href='https://calendly.com/toneagency/tone-agency-call'>Book a call</a>

        </div>        
      </div>
  
    </div>
    )
}
export default Book;
