import CardRow from './cards/cardRow'

import { useState } from 'react'

const Games = () => {
    const [ cardListOne ] = useState([
        {
            id: 1,
            category: 'Carnival',
            title: 'Donkey Derby',
            wistiaID: 'k2g3vvruop',
            description: 'A classic game, Donkey Derby involves rolling as many balls into the holes as possible - the higher the score you get!',
            link: 'https://tone.co.uk/games/donkey-derby/'
        },
        {
            id: 2,
            category: 'Sports',
            title: 'Penalty Shooter',
            wistiaID: '0t0xasctm4',
            description: 'A popular point and shoot game themed around soccer with the aim to beat the CPU on goals scored.',
            link: 'https://tone.co.uk/games/national-champions-league/'

        },
        {
            id: 7,
            category: 'Skill',
            title: 'Build a Burger',
            image: 'burger.jpg',
            wistiaID: '',
            description: `Can you build a sky-high burger at Cafe Casino? Be careful not to let the server drop anything!`,
            link: 'https://cafecasino.com/build-a-burger-mini-game/'

        },
        {
            id: 3,
            category: 'Platform',
            title: 'Running Man',
            wistiaID: '4la16qw0tn',
            description: 'Simply run and jump over customised obstacles to reach the finish line and win - it is harder than you think!',
            link: 'https://staging.tone.co.uk/kindred-games/kolikkopelit/folklore-runner/en/'

        },
        {
            id: 4,
            category: 'Sports',
            title: 'Classic Pong',
            wistiaID: 'u8ixxm3evi',
            description: 'Show off your tennis skills with classic Pong by simply bouncing the ball between you and the CPU to get the highest score.',
            link: 'https://www.unibet.co.uk/hub/unipong/gb/'

        },
        {
            id: 5,
            category: 'Action',
            title: 'Charm Chaser',
            wistiaID: 'w529cpovvs',
            description: `Make your way through the maze 'Pac-Man' style and collect lucky charms but watch out for the bad luck charms!`,
            link: 'https://staging.tone.co.uk/kindred-games/maria-casino/charm-chaser/en/'

        },
        {
            id: 6,
            category: 'Memory',
            title: 'Card Scramble',
            image: 'tarot.jpg',
            wistiaID: '',
            description: 'Adaptable game to test your memory skills - can you remember all of the pairs in just a few seconds?',
            link: 'https://staging.tone.co.uk/kindred-games/casinohuone/tarot/en/'

        },
        {
            id: 8,
            category: 'Skill',
            title: 'Mirror Balance',
            image: 'mirror.jpg',
            wistiaID: '',
            description: `This simple, yet addictive game challenges you to stack as many mirrors as possible to reach the target line, without them toppling over!`,
            link: 'https://staging.tone.co.uk/kindred-games/casinohuone/mirror-balance/en/'

        },
        {
            id: 9,
            category: 'Reaction',
            title: 'Brolly Bash',
            wistiaID: 'hodh513gub',
            description: `It's bad luck to open brolly's indoors so make sure you click on as many as you can, but don't click on the footballs or you'll lose points!`,
            link: 'https://staging.tone.co.uk/kindred-games/unibet/brolly-bash/en/'

        }
    ])

    

    return (
    <div className="bg-tone-darkpurple text-white py-6 pt-2 md:py-12">
		<div className="md:container mx-auto w-full md:w-[90%] lg:max-w-[1500px] md:pl-0">
			<div className="section-title text-center mx-auto max-w-screen-lg px-6 lg:px-0 pb-8 md:pb-20">
				<h2 className="font-bold text-4xl leading-7 md:text-4xl lg:text-5xl xl:text-6xl uppercase tracking tracking-wider mb-4 md:mb-8 text-tone-pink grad"><span className='block md:inline'>Mobile games</span> from Tone</h2>
                <p className="text-sm md:text-lg xl:text-xl font-body leading-normal xl:leading-relaxed">
                    Unlock the superpower of mobile games to level up your audience engagement and tap into the huge appetite for gamified content.
                    From social channels to exhibitions and powering up your CRM database, this type of content is helping innovative teams nurture and convert users into customers.</p>

                <p className="text-sm md:text-lg xl:text-xl font-body leading-normal xl:leading-relaxed mt-4">
                    Book a call today to talk through how!
                </p>
			</div>

            
                <CardRow cards={cardListOne} />
			
		</div>

	</div>
    )
}
export default Games;
