import React from 'react'
import ReactPlayer from 'react-player'

const CardRow = ({ cards }) => {
    return (

        <div className="cards__holder  px-6 flex md:grid md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8 lg:gap-10 xl:gap-16 2xl:gap-20">
        {cards.map(card => (
            <div className="card transition will-change-transform xl:scale-100 xl:hover:scale-105 duration-500 rounded-lg xl:rounded-2xl overflow-hidden card bg-white bg-opacity-10 flex w-64 shrink-0 md:w-full flex-col shadow-lg border border-wh-font border-opacity-10">
                
                {card.image && <img src={card.image} />}

                { card.wistiaID && 
                <div className="player-wrapper">
                <ReactPlayer width='100%' height='100%' className="react-player" playsinline volume={0} muted={true} playing url={`https://tone.wistia.com/medias/${card.wistiaID}`} />
                </div>
                }
                <div className="content__holder p-5  md:p-8 2xl:p-10 flex h-full flex-col">
                    <h3 className="text-base md:text-lg mb-0 tracking-widest uppercase text-white text-opacity-75">{card.category}</h3>
                    <h2 className="text-xl md:text-3xl xl:text-3xl mb-2 md:mb-3 tracking-wider uppercase font-upheaval text-tone-pink">{card.title}</h2>
                    <p className='text-sm md:text-base md:leading-relaxed mb-4 md:mb-6 mt-0 font-body flex-1'>{card.description}</p>
                    <a href={card.link} target="_blank" rel='noopener noreferrer' className="rounded-lg xl:rounded-xl leading-none transition hover:bg-tone-pink hover:text-white p-4 uppercase font-superstar md:text-2xl tracking-wide border-2 border-tone-pink text-tone-pink text-lg">Play the Game</a>
                </div>
            </div>
        ))}
    </div>

    )
}

export default CardRow